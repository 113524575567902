<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-18 15:16:13
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-19 14:55:25
-->
<template>
 <div>
   <a-modal title="填写信息" v-model="visible" :width="600" @ok="onConfirm" okText="确定" @cancel="onCancel" cancelText="取消"> :maskClosable="false"
      <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{span:6}" :wrapper-col="{span:18}">
        <a-form-model-item label="发票类型" prop="taxType">  
          <a-radio-group v-model="rowData.taxType">
            <a-radio :value="1"> 普通发票 </a-radio>
            <a-radio :value="2"> 增值税发票 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="抬头类型" prop="taxTitleType">
         <a-radio-group v-model="rowData.taxTitleType" @change="onChange">
            <a-radio :value="1"> 个人 </a-radio>
            <a-radio :value="2"> 公司 </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="发票抬头" prop="taxTitle">
          <a-input v-model="rowData.taxTitle"></a-input>
        </a-form-model-item>
        <div v-if="checkRadio === 2">
          <a-form-model-item label="纳税人识别码" :prop="checkRadio === 2 ? 'taxNum' : ''">
            <a-input v-model="rowData.taxNum"></a-input>
          </a-form-model-item>
          <a-form-model-item label="注册地址" prop="">
            <a-input v-model="rowData.taxComAddr"></a-input>
          </a-form-model-item>
          <a-form-model-item label="注册电话" prop="">
            <a-input v-model="rowData.taxComPhone"></a-input>
          </a-form-model-item>
          <a-form-model-item label="开户银行" prop="">
            <a-input v-model="rowData.taxBankName"></a-input>
          </a-form-model-item>
          <a-form-model-item label="银行账号" prop="">
            <a-input v-model="rowData.taxBankCardNum"></a-input>
          </a-form-model-item>
        </div>
     </a-form-model>
   </a-modal>
 </div>
</template>

<script>
import {addOrderInfo, editOrderInfo} from '@/views/order_info/api/OrderInfoApi';

export default {
  data() {
    return {
      visible: false,
      rowData: {},
      formRule: {
        taxType: [
          {required: true, message: '请选择发票类型', trigger: 'change'}
        ],
        taxTitleType: [
          {required: true, message: '请选择抬头类型', trigger: 'change'}
        ],
        taxTitle: [
          {required: true, message: '请输入发票抬头', trigger: 'blur'}
        ],
        taxCompany: [
          {required: true, message: '请输入单位名称', trigger: 'blur'}
        ],
        taxNum: [
          {required: true, message: '请输入纳税人识别号', trigger: 'blur'}
        ],
      },
      checkRadio: 1,
      orderNo: ''
    }
  },
  created() {},
  methods: {
    isShow(row) {
      this.visible = true
      this.rowData = {}
      this.detail = row
      this.axios.get(`/api/order/order/orderInfo/selectOrderTaxByOrderNo/${row.orderCode}`).then(res => {
        this.rowData = res.body != null ? res.body : {}
        this.rowData.taxTitleType = Number(res.body.taxTitleType)
        this.checkRadio = Number(res.body.taxTitleType)
      })
    },

    onCancel() {
      this.rowData = {}
      this.visible = false
    },

    onChange(val) {
      this.checkRadio = val.target.value
    },
    
    onConfirm() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        this.rowData.orderNo = this.detail.orderCode
        this.rowData.taxAmount = this.detail.totalAmount
        this.axios.post(`/api/order/order/orderInfo/editOrderInfoTax`, this.rowData).then(res => {
          this.visible = false
          this.$emit('reload')
          this.$message.success(res.message)
          this.rowData = {}
        })
      })
    },
  }
 }
</script>

<style scoped>

</style>
